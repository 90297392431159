// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import SecureLS from "secure-ls";
import axios from "axios";

const firebaseConfig = {
    apiKey: "AIzaSyBpUUtxrlquqWEfU8sJP8QWk7a0PKK0SH8",
  authDomain: "theattarco-af5f5.firebaseapp.com",
  projectId: "theattarco-af5f5",
  storageBucket: "theattarco-af5f5.appspot.com",
  messagingSenderId: "776697239431",
  appId: "1:776697239431:web:1e4816474a0b538d5aa522",
  measurementId: "G-9S7DSED970"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);
const ls = new SecureLS({ encodingType: 'aes' });


// Export the messaging instance and the generateToken function
export const generateToken = async () => {
    const email = ls.get('userEmail');
    const name = ls.get('userName');

    if (email && name) {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            const token = await getToken(messaging, {
                vapidKey:"BCwPc1WwEWUPqfb7fbSkqIjyrarGP9CUzW6vlS8aQr2Iv7Lzw_tAf8Iqf8ndxfS-Vw9rs2NE0q4At2js4O3Q2F8"
            });
            addtoTokens(email, token)
        }
    }
};

const addtoTokens = async (username, token) => {
    const isregistered = ls.get('tokenregistered');
    if (isregistered == 'false' || isregistered == '') {
        try {
            const response = await axios.get('https://theattarco.com/api/client_api/notifications/registertokens.php', {
                params: {
                    token: token,
                    email: username
                }
            });
            if (response.data.success) {
                ls.set('tokenregistered', 'true');
            } else {
                ls.set('tokenregistered', 'false');
            }
        } catch (error) {
            console.error('Error adding to cart:', error);
            return false; // Indicate failure
        }
    }
};




export { messaging }; // Add this line to export messaging
