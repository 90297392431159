import React, { Suspense } from 'react';
import './Home.css';
import Header from '../../components/Header/Header';
import { Container } from 'react-bootstrap';
import InspiredProducts from '../../components/Products/InspiredProducts';

// Use React.lazy to lazy load components
const Sliders = React.lazy(() => import('../../components/Sliders/Sliders'));
const OurFragrances = React.lazy(() => import('../../components/OurFragrances/OurFragrances'));
const Features = React.lazy(() => import('../../components/Features/Features'));
const Search = React.lazy(() => import('../../components/Search/Search'));
const Bestsellers = React.lazy(() => import('../../components/Products/BestSellers'));
const StoreInfo = React.lazy(() => import('../../components/StoreInfo/StoreInfo'));
const VideoCard = React.lazy(() => import('../../components/VideoCard/VideoCard'));
const ShopByGender = React.lazy(() => import('../../components/ShopByGender/ShopByGender'));
const Subscribe = React.lazy(() => import('../../components/Subscribe/Subscribe'));
const CouponCodeSlider = React.lazy(() => import('../../components/CouponCodeSlider/CouponCodeSlider'));
const Testimonials = React.lazy(() => import('../../components/Testimonials/Testimonials'));
const BlogsHome = React.lazy(() => import('../../components/Blogs/BlogsHome'));
const RecentlyAdded = React.lazy(() => import('../../components/Products/RecentlyAdded'));

const components = [
  { component: <Sliders key="Sliders" />, key: 'Sliders' },
  { component: <InspiredProducts key="InspiredProducts" />, key: 'InspiredProducts' },
  { component: <Bestsellers key="Bestsellers" />, key: 'Bestsellers' },
  { component: <StoreInfo key="StoreInfo" />, key: 'StoreInfo' },
  { component: <RecentlyAdded key="RecentlyAdded" />, key: 'RecentlyAdded' },
  { component: <CouponCodeSlider key="CouponCodeSlider" />, key: 'CouponCodeSlider' },
  { component: <OurFragrances key="OurFragrances" />, key: 'OurFragrances' },
  { component: <Features key="Features" />, key: 'Features' },
  { component: <VideoCard key="VideoCard" />, key: 'VideoCard' },
  { component: <ShopByGender key="ShopByGender" />, key: 'ShopByGender' },
  // { component: <Testimonials key="Testimonials" />, key: 'Testimonials' },
  { component: <Subscribe key="Subscribe" />, key: 'Subscribe' },
  { component: <BlogsHome key="BlogsHome" />, key: 'BlogsHome' },
  // Uncomment if you want to include AppIntro
  // { component: <AppIntro key="AppIntro" />, key: 'AppIntro' }
];

function Home() {
  return (
    <div className='sectionView'>
      {components.map(({ component, key }) => (
        <Suspense key={key} fallback={<div>Loading...</div>}>
          <div data-key={key}>
            {component}
          </div>
        </Suspense>
      ))}
    </div>
  );
}

export default Home;
