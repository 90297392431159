import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Row, Col, Image, Button, ButtonGroup, ButtonToolbar, Tabs, Tab, Card, Form, ListGroup, Accordion, ProgressBar } from 'react-bootstrap';
import { CartContext } from '../../Utils/CartContext';
import { WishlistContext } from '../../Utils/WishlistContext';
import RecentlyAdded from '../../components/Products/RecentlyAdded';
import DOMPurify from 'dompurify';
import { IoAddSharp, IoArrowDown, IoAtCircle, IoCaretDown, IoCaretUp, IoCheckmarkCircle, IoChevronDown, IoChevronUp, IoCloudyNight, IoFemale, IoFlower, IoFootstepsOutline, IoFootstepsSharp, IoLeaf, IoLogoAppleAr, IoMale, IoMaleFemale, IoMan, IoMenu, IoPeople, IoPersonOutline, IoPersonSharp, IoRemoveSharp, IoSparkles, IoStar, IoStarHalf, IoStarOutline, IoStop, IoSunny, IoTimerOutline, IoTriangleSharp } from 'react-icons/io5';
import './Product.css';
import ImageViewer from "react-simple-image-viewer";
import { AiOutlineHome } from 'react-icons/ai';
import Marquee from 'react-fast-marquee';
import amber from '../../assets/images/amber.webp';
import bergamot from '../../assets/images/bergamot.webp';
import lavender from '../../assets/images/lavender.webp';
import { TbHours24 } from 'react-icons/tb';
import Slider from 'react-slick';
import SecureLS from "secure-ls";
import SweetAlert2 from 'react-sweetalert2';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import ReactStars from 'react-rating-stars-component';
import StarRating from '../../Utils/StarRating';

const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    for (let i = 0; i < fullStars; i++) {
        stars.push(<IoStar key={`full-${i}`} color='gold' />);
    }

    if (halfStar) {
        stars.push(<IoStarHalf key="half" color='gold' />);
    }

    for (let i = 0; i < emptyStars; i++) {
        stars.push(<IoStarOutline key={`empty-${i}`} />);
    }

    return stars;
};

const ProductDetail = () => {
    const navigate = useNavigate();
    const ls = new SecureLS({ encodingType: 'aes' });

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToTop();
    }, [navigate]);

    const { productName } = useParams();
    console.log(productName);
    const product_name = productName.replace(/-/g, ' ');
    const baseURL = 'https://theattarco.com/';

    const [item, setItem] = useState(null);
    const [mainImage, setMainImage] = useState('');
    const [perfumeMainImage, setMainPerfumeImage] = useState('');
    const [selectedSize, setSelectedSize] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const { addToCart } = useContext(CartContext);
    const { addToWishlist, removeFromWishlist } = useContext(WishlistContext);
    const [swalProps, setSwalProps] = useState({});
    const [testimonials, setTestimonials] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [formData, setFormData] = useState({
        comment: '',
        rating: '',
        product_images: []
    });

    useEffect(() => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        username ? setIsLoggedIn(true) : setIsLoggedIn(false);
        fetchProduct(sessionId, username, product_name);
    }, [product_name]);

    const fetchProduct = async (sessionId, username, product_name) => {
        try {
            const response = await axios.get(`${baseURL}/api/client_api/products/getproductdetails/index.php`, {
                params: {
                    name: product_name,
                    email: username || 'na',
                    ipadd: sessionId || 'na'
                }
            });
            const fetchedItem = response.data.data[0];
            console.log(response.data.data);
            setItem(fetchedItem);
            setMainImage(baseURL + fetchedItem.images[0]);
            setMainPerfumeImage(fetchedItem.perfumeimages ? baseURL + fetchedItem.perfumeimages[0] : '');
            setSelectedSize(fetchedItem.price[0].weight + 'ml'); // Default size
            setTestimonials(fetchedItem.review_and_ratings ? fetchedItem.review_and_ratings : []);
            setIsLoading(false);
        } catch (err) {
            setError(err.message);
            setIsLoading(false);
        }
    };

    const handleSizeChange = (size) => {
        setSelectedSize(size)
    };
    const handleQuantityChange = (change) => setQuantity(Math.max(1, quantity + change));
    // const handleThumbnailClick = (image) => setMainImage(baseURL + image);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const handleThumbnailClick = (index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const handleAddToCart = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price.find(p => p.weight + 'ml' === selectedSize);

        const success = username
            ? await addToCart('na', username, { selectedProdCode: selectedPriceDetails.prodcode, quantity })
            : await addToCart(sessionId, 'na', { selectedProdCode: selectedPriceDetails.prodcode, quantity });

        if (success) {
            setSwalProps({
                show: true,
                title: item.name,
                text: 'Added to Cart!',
                icon: 'success',
                confirmButtonText: 'Okay',
                confirmButtonColor: '#ae8625 '
            });
        } else {
            console.error('Failed to add to cart');
            setSwalProps({
                show: true,
                title: item.name,
                text: 'Failed to add to cart!',
                icon: 'error',
                confirmButtonText: 'Try again.',
                confirmButtonColor: '#ff0000'
            });
        }
    };

    const handleAddToWishlist = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price.find(p => p.weight + 'ml' === selectedSize);
        const success = username
            ? await addToWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await addToWishlist(sessionId || 'na', 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });
        if (success) {
            console.log(`Added ${item.name} to wishlist`);
        } else {
            console.error('Failed to add to wishlist');
        }
    };

    const handleRemoveFromWishlist = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price.find(p => p.weight + 'ml' === selectedSize);
        const success = username
            ? await removeFromWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await removeFromWishlist(sessionId || 'na', 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });
        if (success) {
            console.log(`Removed ${item.name} from wishlist`);
        } else {
            console.error('Failed to remove from wishlist');
        }
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    const sanitizeHTML = (html) => {
        return DOMPurify.sanitize(html);
    };


    const generateSlug = (name) => {
        return name.replace(/ /g, '-').replace(/[^\w-]+/g, '');
    };


    const Breadcrumb = ({ product }) => {
        // Extract collection name and product name
        const collections = JSON.parse(product.collection);
        const excludedCollections = ["All Products", "Attars", "Unisex", "Men", "Women", "For Her", "For Him"];
        const filteredCollections = collections.filter(collection => !excludedCollections.includes(collection));
        const collectionName = filteredCollections.length > 0 ? filteredCollections[0] : 'All Products';
        const productName = product.name;

        return (
            <div className='breadcrumb-container'>
                <div className='breadcrumb'>
                    <Link to='/' className='breadcrumb-item text-white me-2'><AiOutlineHome size={20} /></Link>
                    <div className='breadcrumb-item text-white me-2'>Collections</div>
                    <Link to={`/collection/${generateSlug(collectionName)}`} className='breadcrumb-item text-white me-2'>{collectionName}</Link>
                    <div className='breadcrumb-item text-white me-2'>{productName}</div>
                </div>
            </div>
        );
    };

    const Discount = ((item.price.find(p => p.weight + 'ml' === selectedSize)?.basePrice - item.price.find(p => p.weight + 'ml' === selectedSize)?.discountedPrice) / item.price.find(p => p.weight + 'ml' === selectedSize)?.basePrice) * 100;
    const DiscountNearestPercentage = Math.round(Discount);

    const optionsMap = {
        "Sillage": {
            description: "The trail your perfume leaves behind",
            icon: <IoFootstepsOutline className='ms-1' />
        },
        "Projection": {
            description: "The reach of your scent",
            icon: <IoLogoAppleAr className='ms-1' />
        },
        "Longevity": {
            description: "How long your scent lasts",
            icon: <IoTimerOutline className='ms-1' />
        }
    };

    const notesIcons = {
        "top-notes": <IoCaretUp className='me-2' />,
        "middle-notes": <IoStop className='me-2' />,
        "bottom-notes": <IoCaretDown className='me-2' />
    };

    const NotesSection = ({ data }) => (
        <div>
            {data.map((item, index) => {
                if (item.option === 'notes-description' || item.option === 'notes-tags' || item.option === 'notes-image') {
                    return null; // Skip non-note sections
                }

                const icon = notesIcons[item.option];
                const sectionTitle = item.option.replace('-', ' ').toUpperCase();

                return (
                    <div key={index} className='mb-2'>
                        <h5 className='mb-0 text-white'>
                            {icon}
                            {sectionTitle}
                        </h5>
                        {item.values.map((value, i) => (
                            <p key={i} className='mb-0 ms-4 text-white'>{value}</p>
                        ))}
                    </div>
                );
            })}
        </div>
    );

    const NotesDescription = ({ data }) => {
        const description = data.find(item => item.option === 'notes-description')?.values[0] || '';
        const tags = data.find(item => item.option === 'notes-tags')?.values || [];

        return (
            <div>

                {tags.length > 0 && (
                    <div className='my-3'>
                        {tags.map((tag, index) => (
                            <p key={index} className='mb-0 fs-2 fw-bold text-white'>{tag}.</p>
                        ))}
                    </div>
                )}
                {description && (
                    <div className='mb-2'>
                        <p className='mb-0 fs-6 text-white'>{description}</p>
                    </div>
                )}
            </div>
        );
    };

    const NotesImage = ({ data }) => {
        const image = data.find(item => item.option === 'notes-image')?.values[0] || '';

        return (
            <div>
                {image.length > 0 && (
                    <img src={baseURL + image} width={250} />
                )}
            </div>
        );
    };

    const genderIcons = {
        "Men": <IoMale className="men-icon" />,
        "Women": <IoFemale className="men-icon" />,
        "Unisex": <IoMaleFemale className="men-icon" />,
    };

    const dayIcons = {
        "Night": <IoCloudyNight className="all-day-icon" />,
        "Day": <IoSunny className="all-day-icon" />,
        "All Day": <TbHours24 className="all-day-icon" />,
    };

    const GenderDaySection = ({ data }) => {
        const genderValue = data.find(item => item.option === 'Gender')?.values[0] || '';
        const dayValue = data.find(item => item.option === 'Day')?.values[0] || '';

        return (
            <div className="men-all-day-container">
                {genderValue && (
                    <div className="men-section">
                        {genderIcons[genderValue] || <IoMale className="men-icon" />}
                        <span className="men-text">{genderValue}</span>
                    </div>
                )}
                {dayValue && (
                    <div className="all-day-section">
                        <span className="all-day-text">{dayValue}</span>
                        {dayIcons[dayValue] || <IoSunny className="all-day-icon" />}
                    </div>
                )}
            </div>
        );
    };

    const settings = {
        customPaging: function (i) {
            // Return an image element with the thumbnail for the custom paging
            return (
                <a>
                    <img
                        src={item.price.find(p => p.weight + 'ml' === selectedSize)?.isPerfume ? (item.perfumeimages[i] ? baseURL + item.perfumeimages[i] : '') : (item.images[i] ? baseURL + item.images[i] : '')}
                        alt={`Thumbnail ${i}`}
                        className="slick-thumbnail me-2"
                    />
                </a>
            );
        },
        dots: true,
        // dotsClass: "slick-dots slick-thumb",
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    const RatingsCard = ({ item }) => (
        <Card className='h-100 me-2 mb-2 bg-transparent border-white rounded-3' style={{ maxHeight: '18rem', minHeight: '10rem', }}>
            <Card.Body>
                <Card.Text className='text-start mb-1'>
                    {renderStars(item.rating)}
                </Card.Text>
                <Card.Text className='text-start text-white mb-1'>{item.name} <IoCheckmarkCircle color="green" />
                </Card.Text>
                <Card.Text className='text-start text-white mb-1'>{item.comment}</Card.Text>
                {item.product_images.map((img, index) => (
                    <img key={index} src={img} alt="Product" width="100" className="mr-2" />
                ))}
            </Card.Body>
        </Card>
    );

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 3) {
            alert("You can only upload up to 3 images");
            return;
        }

        const validFiles = files.filter(file => file.size <= 1024 * 1024); // 1MB limit
        if (validFiles.length !== files.length) {
            alert("Some images are larger than 1MB");
            return;
        }

        setFormData({
            ...formData,
            product_images: validFiles
        });
    };

    const handleRatingChange = (newRating) => {
        setFormData({ ...formData, rating: newRating });  // Only updates rating, doesn't re-render whole component
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const username = ls.get('userEmail');

        const updatedFormData = {
            ...formData,
            username: username,
            productId: item.id
        };

        // console.log("Form submitted", updatedFormData);

        axios.post('https://theattarco.com/api/client_api/products/rateProducts/', updatedFormData)
            .then(response => {
                // console.log(response.data);

                if (response.data.status == 'success') {
                    // Clear form data
                    setFormData({
                        // Reset form fields to their initial values
                        comment: '',
                        rating: '',
                        product_images: [] // Adjust this based on how you store images
                    });

                    const sessionId = sessionStorage.getItem('sessionId');
                    const username = ls.get('userEmail');
                    fetchProduct(sessionId, username, product_name);
                } else {
                    alert('Error submitting a review!')
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <>
            <Container fluid className="px-lg-5 px-md-5 px-sm-5 sectionView">
                <Breadcrumb product={item} />
                <Row className="my-4">
                  {/* only for mobile */}
                  <div className="d-md-none">
                        <Slider {...settings}>
                            {
                                item.price.find(p => p.weight + 'ml' === selectedSize)?.isPerfume ?
                                    item.perfumeimages.map((perfumeimage, index) => (
                                        <div key={index}>
                                            <Image
                                                src={`${baseURL}${perfumeimage}?w=300&h=300&fit=crop`} // Adjust width and height as needed
                                                alt={`Perfume Image ${index + 1}`}
                                                className='bg-transparent border-0 p-0 mb-2 mobile-product-image'
                                                loading="lazy" // Lazy loading for mobile images
                                                onClick={() => handleThumbnailClick(index)}
                                            />
                                        </div>
                                    ))
                                    :
                                    item.images.map((image, index) => (
                                        <div key={index}>
                                            <Image
                                                src={`${baseURL}${image}?w=300&h=300&fit=crop`} // Adjust width and height as needed
                                                alt={`Image ${index + 1}`}
                                                className='bg-transparent border-0 p-0 mb-2 mobile-product-image'
                                                loading="lazy" // Lazy loading for mobile images
                                                onClick={() => handleThumbnailClick(index)}
                                            />
                                        </div>
                                    ))
                            }
                        </Slider>
                    </div>
                    {/* only for non mobile */}
                    <Col md={5} className="d-none d-md-block">
                        <Image
                            src={`${baseURL}${item.price.find(p => p.weight + 'ml' === selectedSize)?.isPerfume ? perfumeMainImage : mainImage}?w=600&h=600&fit=crop`} // Adjust size for main image
                            alt={item.name}
                            fluid className='rounded-3'
                            style={{ position: 'sticky', top: '10vh' }}
                            loading="lazy" // Lazy loading for main image
                            onClick={() => handleThumbnailClick(0)}
                        />
                    </Col>
                    <Col md={2} className="d-none d-md-block">
                        <Row>
                            {
                                item.price.find(p => p.weight + 'ml' === selectedSize)?.isPerfume ?
                                    item.perfumeimages.map((image, index) => (
                                        <Col xs={12} key={index}>
                                            <Image
                                                src={`${baseURL}${image.replace('uploads', 'media').replace('w3prouploads', 'karstar')}?w=300&h=300&fit=crop`} // Adjust for thumbnails
                                                alt={`Perfume Thumbnail ${index + 1}`}
                                                thumbnail
                                                className='bg-transparent border-0 p-0 mb-2'
                                                loading="lazy" // Lazy loading for thumbnails
                                                onClick={() => handleThumbnailClick(index)}
                                            />
                                        </Col>
                                    ))
                                    :
                                    item.images.map((image, index) => (
                                        <Col xs={12} key={index}>
                                            <Image
                                                src={`${baseURL}${image.replace('uploads', 'media').replace('w3prouploads', 'karstar')}?w=300&h=300&fit=crop`} // Adjust for thumbnails
                                                alt={`Thumbnail ${index + 1}`}
                                                thumbnail
                                                className='bg-transparent border-0 p-0 mb-2'
                                                loading="lazy" // Lazy loading for thumbnails
                                                onClick={() => handleThumbnailClick(index)}
                                            />
                                        </Col>
                                    ))
                            }
                        </Row>
                    </Col>
                    {isViewerOpen && (
                        <ImageViewer
                            src={item.price.find(p => p.weight + 'ml' === selectedSize)?.isPerfume ?
                                item.perfumeimages.map(image => `${baseURL}${image}?w=600&h=600&fit=crop`) :
                                item.images.map(image => `${baseURL}${image}?w=600&h=600&fit=crop`)}
                            currentIndex={currentImage}
                            onClose={closeImageViewer}
                            disableScroll={false}
                            backgroundStyle={{
                                backgroundColor: "rgba(0,0,0,0.9)"
                            }}
                            closeOnClickOutside={true}
                        />
                    )}
                    <Col md={5}>
                        <div style={{ position: 'sticky', top: '10vh' }}>
                            <span className="mx-2 fs-4 gradient-text">
                                {item.price.find(p => p.weight + 'ml' === selectedSize)?.isPerfume ? 'Perfume' : 'Attar'}
                            </span>
                            <h2 className='text-white'>{item.name}</h2>
                            <span className={`text-${item.price.find(p => p.weight + 'ml' === selectedSize)?.inStock ? 'success' : 'danger'} mx-2`}>
                                {item.price.find(p => p.weight + 'ml' === selectedSize)?.inStock ? 'In stock' : 'Out of stock'}
                            </span>
                            {item?.price.find(p => p.weight + 'ml' === selectedSize)?.average_rating > 0 &&
                                <div className='d-flex'>
                                    <StarRating rating={String(item?.price.find(p => p.weight + 'ml' === selectedSize)?.average_rating)} />
                                    <div className="rating text-white  fw-bold fs-6 ms-2">{String(item?.price.find(p => p.weight + 'ml' === selectedSize)?.average_rating)}/5</div>
                                </div>
                            }
                            <span className="text-light text-decoration-line-through">Rs {item.price.find(p => p.weight + 'ml' === selectedSize)?.basePrice || item.price[0].basePrice}</span>
                            <h4 className='text-white'>Rs {item.price.find(p => p.weight + 'ml' === selectedSize)?.discountedPrice || item.price[0].discountedPrice}
                                <span className="badge abstext-danger text-danger ms-2 rounded-5" style={{ background: '#ff333352' }}>{DiscountNearestPercentage}%</span>
                            </h4>
                            <p className='text-white' dangerouslySetInnerHTML={{ __html: sanitizeHTML(item.description) }} ></p>

                            <h5 className='text-white mb-3'>Choose Size</h5>
                            <ButtonToolbar className="mb-4">
                                {item.price.map((p, index) => (
                                    <Button
                                        key={index}
                                        variant={selectedSize === p.weight + 'ml' ? 'light' : 'outline-light'}
                                        onClick={() => handleSizeChange(p.weight + 'ml')}
                                        className="mx-1 rounded-5"
                                    >
                                        {p.weight}ml
                                    </Button>
                                ))}
                            </ButtonToolbar>
                            <h5 className='text-white mb-3'>Bottle Size</h5>
                            <ButtonToolbar className="mb-5">
                                <Button
                                    variant={'light'}
                                    className="mx-1 rounded-5"
                                >
                                    {selectedSize}
                                </Button>
                            </ButtonToolbar>

                            <div className="quantity-selector mb-3 d-flex">
                                <ButtonGroup className="me-3">
                                    <Button className='bg-white text-black border-0 rounded-start-5' onClick={() => handleQuantityChange(-1)}>
                                        <IoRemoveSharp />
                                    </Button>
                                    <span className="align-self-center bg-white px-4 py-2 fw-bold">{quantity}</span>
                                    <Button className='bg-white text-black border-0 rounded-end-5' onClick={() => handleQuantityChange(1)}>
                                        <IoAddSharp />
                                    </Button>
                                </ButtonGroup>
                                <Button block="true" disabled={item.price.find(p => p.weight + 'ml' === selectedSize)?.inStock ? false : true} variant="outline-light" className='rounded-5 w-75' onClick={handleAddToCart}>Add to Cart</Button>
                            </div>
                            <Link to={item.price.find(p => p.weight + 'ml' === selectedSize)?.inStock ? '/checkout' : ''} state={{ item: item, isBuyNow: 'no', isCart: 'yes', selectedSize: selectedSize }} >
                                <Button block="true" disabled={item.price.find(p => p.weight + 'ml' === selectedSize)?.inStock ? false : true} variant="light" className='rounded-5 w-100'>Buy Now</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Tabs defaultActiveKey="details" id="product-tabs" className="container-fluid px-lg-5 px-md-5 px-sm-5 mb-3 mt-5 d-flex justify-content-around align-items-center custom-tabs" variant="underline" >
                <Tab eventKey="details" title="Product Details" className='container-fluid px-lg-5 px-md-5 px-sm-5'>
                    <Row>
                        <Col lg={8}>
                            <Accordion defaultActiveKey="0" className='bg-transparent'>
                                <Accordion.Item eventKey="0" className='bg-transparent border-0'>
                                    <Accordion.Header className='bg-transparent text-white'>
                                        <IoSparkles className='me-2' /> Intensity
                                    </Accordion.Header>
                                    <Accordion.Body className='text-white'>
                                        <Row>
                                            <Col className='intensity-details' sm={12} xs={12} md={6} lg={8}>
                                                {item.intensity.map((item, index) => {

                                                    if (item.option === 'lastinghours' || item.option === 'lastinghours-image') {
                                                        return null; // Skip non-note sections
                                                    }
                                                    const optionInfo = optionsMap[item.option] || {};
                                                    const description = optionInfo.description || "Description not available";
                                                    const icon = optionInfo.icon || null;

                                                    return (
                                                        <div key={index}>
                                                            <ProgressBar
                                                                variant='dark'
                                                                now={parseInt(item.values[0], 10)}
                                                                label={item.option}
                                                                className='rounded-5'
                                                            />
                                                            <p className='text-end'>
                                                                {description}
                                                                {icon}
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </Col>
                                            <Col>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" className='bg-transparent border-0'>
                                    <Accordion.Header>
                                        <IoFlower className='me-2' />
                                        Notes and Composition</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col className='d-flex flex-column justify-content-center'>
                                                <NotesSection data={item.notes} />
                                            </Col>
                                            <Col>
                                                <NotesImage data={item.notes} />
                                            </Col>
                                        </Row>
                                        <NotesDescription data={item.notes} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" className='bg-transparent border-0'>
                                    <Accordion.Header>
                                        <IoPersonSharp className='me-2' />
                                        Personality</Accordion.Header>
                                    <Accordion.Body>
                                        <GenderDaySection data={item.personality} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col lg={4}>
                            <div className="text-section">
                                <h4>Lasts {item.intensity.find(item => item.option === 'lastinghours')?.values[0] || ''}+ hours</h4>
                                <p>
                                    Experience the captivating scent that lasts for over 7 hours, with lingering notes that will mesmerize your senses.
                                </p>
                                <img src={baseURL + item.intensity.find(item => item.option === 'lastinghours-image')?.values[0] || ''} width={320} />
                            </div>
                        </Col>
                    </Row>

                </Tab>
                <Tab eventKey="reviews" title="Rating & Reviews" className='container-fluid px-lg-5 px-md-5 px-sm-5'>
                    <Row>
                        <Col lg={8}>
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                            >
                                <Masonry>
                                    {testimonials.map((testimonial, index) => (
                                        <RatingsCard item={testimonial} key={index} />
                                    ))}
                                </Masonry>
                            </ResponsiveMasonry>
                        </Col>
                        <Col md={4}>
                            {isLoggedIn ?
                                <>
                                    <h3 className='text-white'>Submit a Review</h3>
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formRating">
                                            <Form.Label className="text-white">Rating</Form.Label>
                                            <ReactStars
                                                count={5}
                                                onChange={handleRatingChange}
                                                size={34}
                                                isHalf={true}
                                                emptyIcon={<IoStarOutline />}
                                                halfIcon={<IoStarHalf color='gold' />}
                                                fullIcon={<IoStar color='gold' />}
                                                activeColor="#ffd700"
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="formComment">
                                            <Form.Label className='text-white'>Comment</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                value={formData.comment}
                                                onChange={(e) => setFormData({ ...formData, comment: e.target.value })}
                                                required
                                            />
                                        </Form.Group>

                                        {/* <Form.Group controlId="formProductImages">
                                            <Form.Label className='text-white'>Product Images (Max 3, each less then 1MB)</Form.Label>
                                            <Form.Control
                                                type="file"
                                                multiple
                                                onChange={handleImageChange}
                                                accept="image/*"
                                            />
                                        </Form.Group> */}

                                        <Button variant="primary" className='bg-white text-black border-0 rounded-5 w-100 mt-3' type="submit">
                                            Submit Review
                                        </Button>
                                    </Form>
                                </>
                                :
                                <div className='d-flex flex-column align-items-center justify-content-center'>
                                    <p className='text-white'>Login/Signup to add a review</p>
                                    <Button variant="primary" className='bg-white text-black border-0 rounded-5 w-50' as={Link} to='/auth/login'>
                                        Login
                                    </Button>
                                </div>
                            }
                        </Col>
                    </Row>

                </Tab>
                <Tab eventKey="faqs" title="FAQs">
                    {/* FAQs content */}
                </Tab>
            </Tabs>
            <RecentlyAdded />
            <SweetAlert2
                didClose={() => {
                    setSwalProps({
                        show: false,
                    });
                }}
                {...swalProps}
            />
        </>
    );
};

export default ProductDetail;
