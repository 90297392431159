import React from 'react';
import { IoStar, IoStarHalf } from 'react-icons/io5';

const STAR_SIZE = 14;

const StarRating = ({ rating }) => {
    const roundedRating = Math.round(rating * 2) / 2; // round to nearest 0.5
    const fullStars = Math.floor(roundedRating);
    const halfStars = Math.ceil(roundedRating - fullStars);
    const emptyStars = 5 - fullStars - halfStars;

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }}>
            {[...Array(fullStars)].map((_, index) => (
                <IoStar key={`full-${index}`} size={STAR_SIZE} color="#F5CC16" />
            ))}
            {[...Array(halfStars)].map((_, index) => (
                <IoStarHalf key={`half-${index}`} size={STAR_SIZE} color="#F5CC16" />
            ))}
            {[...Array(emptyStars)].map((_, index) => (
                <IoStar key={`empty-${index}`} size={STAR_SIZE} color="#D3D3D3" />
            ))}
        </div>
    );
};

export default StarRating;
