import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import './Products.css';
import ProductCard from '../ProductCard/ProductCard';
import { IoArrowBackCircleOutline, IoArrowBackOutline, IoArrowForwardOutline, IoChevronBack, IoChevronForward } from 'react-icons/io5';
function RecentlyAdded() {
    const [loading, setLoading] = useState(true);
    const [activeSlide, setActiveSlide] = useState(0);
    const baseUrl = 'https://theattarco.com/';

    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    const productsSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        swipe: true,
        slidesToShow: 4.4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: false,
        arrows: false,
        beforeChange: (current, next) => {
            setActiveSlide(next);
        },
        responsive: [
            {
                breakpoint: 1400, // xl (extra large)
                settings: {
                    slidesToShow: 4.4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1200, // lg (large)
                settings: {
                    slidesToShow: 3.2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992, // md (medium)
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 768, // sm (small)
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 576, // xs (extra small)
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 486, // xxs (extra extra small)
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const [recentlyAdded, setRecentlyAdded] = useState([])

    useEffect(() => {
        getFragrances();
    }, [])

    const getFragrances = async () => {
        try {
            const response = await axios.get('https://theattarco.com/api/client_api/RecentlyAdded/index.php');
            setRecentlyAdded(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <Container fluid className='px-lg-0 px-md-0 px-sm-0 px-0'>
                <section className='pt-3'>
                    <Row className="g-0">
                        <Col>
                            <div className='d-flex justify-content-between align-items-center px-lg-5 px-md-5 px-sm-5 px-3'>
                                <div className="">
                                    <h4 className='title text-white py-2 text-uppercase'><span className='gradient-text'>Recently </span>Added</h4>
                                </div>
                                <div>
                                    <button
                                        className="btn me-2 swiper-arrows"
                                        onClick={previous}
                                        style={{
                                            border: `2px solid ${activeSlide == 0 ? '#333' : '#fff'}`,
                                        }}
                                    >
                                        <IoChevronBack color={activeSlide == 0 ? '#333' : '#fff'} size={25} />
                                    </button>
                                    <button
                                        style={{
                                            border: '2px solid #fff',
                                        }}
                                        className="btn swiper-arrows"
                                        onClick={next}
                                    >
                                        <IoChevronForward color='#fff' size={25} />
                                    </button>
                                </div>
                            </div>
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                <div style={{ position: 'relative' }}>
                                    <Slider
                                        ref={slider => {
                                            sliderRef = slider;
                                        }}
                                        {...productsSettings}
                                        className="top-categories pt-2"
                                    >
                                        {recentlyAdded.map((item, index) => (
                                            <ProductCard key={index} item={item} baseUrl={baseUrl} wishlist={item.in_wishlist} cart={item.in_cart} />
                                        ))}
                                    </Slider>
                                </div>
                            )}
                        </Col>
                    </Row>
                </section>
            </Container>
        </div>
    );
}

export default RecentlyAdded;
