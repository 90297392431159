import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap';
import ProductCard from '../../components/ProductCard/ProductCard';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineHome } from 'react-icons/ai';
import axios from 'axios';

function CollectionView() {
    const { collectionName } = useParams();
    const collection_name = collectionName.replace(/-/g, ' ');
    const baseURL = 'https://theattarco.com/';
    const navigate = useNavigate();

    const [collectionItems, setCollectionItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sortOption, setSortOption] = useState('');
    const [filterRating, setFilterRating] = useState(null);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true); // Track if more data is available

    const itemsPerPage = 20; // Adjust based on preference

    useEffect(() => {
        fetchProduct(page);
    }, [collection_name, page]);

    const fetchProduct = async (page) => {
        try {
            const sessionId = sessionStorage.getItem('sessionId');
            const username = sessionStorage.getItem('username');
            const response = await axios.get(`${baseURL}api/client_api/collections/getCollections.php`, {
                params: {
                    name: collection_name,
                    ipadd: sessionId || 'na',
                    email: username || 'na',
                    page: page,
                    limit: itemsPerPage,
                }
            });
            const fetchedItems = response.data.data;

            setCollectionItems(fetchedItems);
            setIsLoading(false);
        } catch (err) {
            setError(err.message);
            setIsLoading(false);
        }
    };


    const sortCollectionItems = (items) => {
        if (sortOption === 'price-asc') {
            return [...items].sort((a, b) => a.price[0].discountedPrice - b.price[0].discountedPrice);
        } else if (sortOption === 'price-desc') {
            return [...items].sort((a, b) => b.price[0].discountedPrice - a.price[0].discountedPrice);
        } else if (sortOption === 'name-asc') {
            return [...items].sort((a, b) => a.name.localeCompare(b.name));
        } else if (sortOption === 'name-desc') {
            return [...items].sort((a, b) => b.name.localeCompare(a.name));
        } else if (sortOption === 'date-asc') {
            return [...items].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        } else if (sortOption === 'date-desc') {
            return [...items].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        }
        return items;
    };

    const filterCollectionItemsByRating = (items) => {
        if (filterRating) {
            return items.filter(item => {
                const ratings = item.average_rating ? JSON.parse(item.average_rating) : [];
                const avgRating = ratings.length ? ratings.reduce((acc, review) => acc + review.rating, 0) / ratings.length : 0;
                return avgRating >= filterRating;
            });
        }
        return items;
    };

    const handleSort = (sortKey) => {
        if (sortKey === 'price-asc') {
            setSortOption('price-asc');
        } else if (sortKey === 'price-desc') {
            setSortOption('price-desc');
        } else if (sortKey === 'name-asc') {
            setSortOption('name-asc');
        } else if (sortKey === 'name-desc') {
            setSortOption('name-desc');
        } else if (sortKey === 'latest') {
            setSortOption('date-desc');
        } else if (sortKey === 'oldest') {
            setSortOption('date-asc');
        }
    };

    const handleFilterByRating = (rating) => {
        setFilterRating(rating);
    };

    let displayedItems = sortCollectionItems(filterCollectionItemsByRating(collectionItems));

    return (
        <div>
            <Container fluid className='sectionView mt-3 px-lg-5 px-md-5 px-sm-5'>
                <div className='breadcrumb'>
                    <a href='/' className='breadcrumb-item text-white me-2'><AiOutlineHome size={20} /></a>
                    <a href='/collections' className='breadcrumb-item text-white me-2'>Collections</a>
                    <div className='breadcrumb-item text-white me-2'>{collection_name}</div>
                </div>
                <h1 className="text-white pt-2 fw-bold">{collection_name}</h1>

                <div className='d-flex mb-3 justify-content-end'>
                    <div className='me-2'>
                        <DropdownButton
                            as={ButtonGroup}
                            variant={'light'}
                            title={`Filter By`}
                            className='rounded-5'
                            onSelect={(e) => handleSort(e)}
                        >
                            <Dropdown.Item eventKey="price-asc">Price: Low to High</Dropdown.Item>
                            <Dropdown.Item eventKey="price-desc">Price: High to Low</Dropdown.Item>
                            <Dropdown.Item eventKey="name-asc">Name: A to Z</Dropdown.Item>
                            <Dropdown.Item eventKey="name-desc">Name: Z to A</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>

                <Row className="g-2">
                    {displayedItems.map((item, index) => (
                        <Col xs={6} md={6} lg={3} key={index}>
                            <ProductCard item={item} baseUrl={baseURL} wishlist={item.in_wishlist} cart={item.in_cart} />
                        </Col>
                    ))}
                </Row>
                {isLoading && <div>Loading...</div>}
                {/* {hasMore && !isLoading && (
                    <div className="text-center">
                        <button onClick={loadMore} className="btn btn-primary mt-3">Load More</button>
                    </div>
                )} */}
            </Container>
        </div>
    );
}

export default CollectionView;
