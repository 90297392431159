import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import './Footer.css';
import razorpaypng from '../../assets/images/razorpay.png'
import Whatsapp from '../Floaters/Whatsapp';
import Callus from '../Floaters/Callus';
import VideoPopup from '../Floaters/VideoPopup';
import { Link } from 'react-router-dom';

const Footer = () => {
     
    return (
        <footer className="footer text-secondary py-5">
            <Container fluid className='px-lg-5 px-md-5 px-sm-5'>
                <Row className="mb-4">
                    <Col xs={12} md={6} lg={4}>
                        <h5 className="mb-3 fw-bold">THE ATTAR CO.</h5>
                        <p className='text-secondary' >We have perfumes that suit your style and which you would love more. From women to men.</p>
                        <div className="social-icons mb-4">
                            <a className='text-secondary' href="https://www.facebook.com/theattarco/" target='_blank'><FaFacebookF className="me-3" /></a>
                            {/* <a className='text-secondary' href="https://www.instagram.com/theattarco/" target='_blank'><FaTwitter className="me-3"/></a> */}
                            <a className='text-secondary' href="https://www.instagram.com/theattarco/" target='_blank'><FaInstagram className="me-3" /></a>
                            <a className='text-secondary' href="https://wa.me/918129616717?text=Hello,I%20have%20a%20question%20about%20https://theattarco.com/" target='_blank'><FaWhatsapp className="me-3" /></a>
                        </div>
                    </Col>
                    <Col xs={6} md={3} lg={2}>
                        <h6 className="mb-3">COMPANY</h6>
                        <ul className="list-unstyled">
                            <li><a className='text-secondary' href="/">Home</a></li>
                            <li><a className='text-secondary' href="/collections">Products</a></li>
                            <li><a className='text-secondary' href="/aboutus">About Us</a></li>
                            <li><a className='text-secondary' href="/store-locator">Store Locator</a></li>
                        </ul>
                    </Col>
                    <Col xs={6} md={3} lg={2}>
                        <h6 className="mb-3">HELP</h6>
                        <ul className="list-unstyled">
                            <li><a className='text-secondary' href="/contactus">Support</a></li>
                            <li><a className='text-secondary' href="/termsandcondtions">Terms & Conditions</a></li>
                            <li><a className='text-secondary' href="/privacypolicy">Privacy Policy</a></li>
                        </ul>
                    </Col>
                    <Col xs={6} md={3} lg={2}>
                        <h6 className="mb-3">Orders</h6>
                        <ul className="list-unstyled">
                            <li><a className='text-secondary' href="/track-order">Orders Tracking</a></li>
                        </ul>
                    </Col>
                    <Col xs={6} md={3} lg={2}>
                        <h6 className="mb-3">POLICIES</h6>
                        <ul className="list-unstyled">
                            <li><a className='text-secondary' href="/exchangereturnpolicy">Return & Exchange Policy</a></li>
                            <li><a className='text-secondary' href="/cookiepolicy">Cookie Policy</a></li>
                            <li><a className='text-secondary' href="/accessibilitypolicy">Accessibility Policy</a></li>
                            <li><a className='text-secondary' href="/shippingpolicy">Shipping Policy</a></li>
                            <li><a className='text-secondary' href="/communityguidelines">Community Guidelines</a></li>
                            <li><a className='text-secondary' href="/copyrightnotice">Copyright Notice</a></li>
                            <li><a className='text-secondary' href="/disclaimer">Disclaimer</a></li>
                            {/* <li><a className='text-secondary' href="/contactinformation">Contact Information</a></li> */}
                        </ul>
                    </Col>
                </Row>
                <Row className="justify-content-between align-items-center">
                    <Col md={6}>
                        <p className="mb-0 text-center text-lg-start fs-6">theattarco.com © 2024, All Rights Reserved</p>

                    </Col>
                    <Col md={3} className="text-end">
                        <img src={razorpaypng} alt="Visa" className="me-2 w-100" />
                    </Col>
                </Row>
            </Container>
            <Whatsapp />
            <Callus />
            {/* <VideoPopup /> */}
        </footer>
    );
}

export default Footer;
